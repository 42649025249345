import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing";
import config from '../../config/config.json';

if (config.environment == 'prod' || config.environment == 'staging') {
    Sentry.init({
      dsn: config.sentry.dsn,
      //release: config.sentry.release,
      environment: config.environment,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
}

//Import all images
function importAll(r) {
    return r.keys().map(r);
}

importAll(require.context('../img', true, /\.(png|jpe?g|svg|gif|ico|webmanifest|xml)$/));

const onDomReady = (callBack: () => void) => {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callBack);
    }
    else {
      callBack();
    }
}

onDomReady(() => {
	let menuBtn = document.querySelector('.menu-btn');
	let nav = document.querySelector('nav');
	let lineOne = document.querySelector('nav .menu-btn .line--1');
	let lineTwo = document.querySelector('nav .menu-btn .line--2');
	let lineThree = document.querySelector('nav .menu-btn .line--3');
	let link = document.querySelector('nav .nav-links');

	menuBtn?.addEventListener('click', () => {
		nav?.classList.toggle('nav-open');
		lineOne?.classList.toggle('line-cross');
		lineTwo?.classList.toggle('line-fade-out');
		lineThree?.classList.toggle('line-cross');
		link?.classList.toggle('fade-in');
	})

	document.getElementById('samepagelink')?.addEventListener('click', () => {
		nav?.classList.toggle('nav-open');
		lineOne?.classList.toggle('line-cross');
		lineTwo?.classList.toggle('line-fade-out');
		lineThree?.classList.toggle('line-cross');
		link?.classList.toggle('fade-in');
	});

	let checkoutButton = document.getElementById('checkout-button') as HTMLButtonElement;
	let errorMessageElem = document.getElementById('error-message');
	let signupFormElem = document.getElementById('inscriptionform');
	checkoutButton?.addEventListener('click', function() {
		if(!signupFormElem) return;

		checkoutButton.disabled = true;
		
		//Basic validation
		if(errorMessageElem) {
			errorMessageElem.innerHTML = '';
		}

		let data = new FormData(signupFormElem as HTMLFormElement);

		fetch('/ajax/preinscription', {
			method: 'POST',
			body: data
		}).then(async response => {
			if(response.status !== 200) {
				if(errorMessageElem) {
					errorMessageElem.innerHTML = 'Assures toi de bien remplir tous les champs!';
				}
				checkoutButton.disabled = false;
				return;	
			}

			const responseData = await response.json();
			window.location.href = responseData.data.checkout_url;
			checkoutButton.disabled = false;
		}).catch(reason => {
			if(errorMessageElem) {
				errorMessageElem.innerHTML = 'Assures toi de bien remplir tous les champs!';
			}
			checkoutButton.disabled = false;
		});
	});

	/*document.getElementById('BYOC3wrap').addEventListener('click', () => {
		window.alert( "Hey tu peux pas réserver si tu viens à porte !");
	});
	document.getElementById('BYOC2wrap').addEventListener('click', () => {
		window.alert( "Pas encore disponible ! (de toute façon le early bird est mieux, t'as un chandail avec ton gamertag, pourquoi tu veux cliquer ici ? ô_o)" );
	});

	document.getElementById('BYOC1wrap').addEventListener('click', () => {
		window.alert( "Trop tard!" );
	});*/
});